import { computed, reactive, ref, watch } from "vue";
import { query } from "gql-query-builder";
import { sortBy } from "lodash";
import HttpClient from "project-blue-http-client";
import { GQL_QUERIES } from "../utils/APIs";
import { LAST_SELECTED_ELEMENT_TO_VIEW } from "./generics";

export const SUBSCRIPTION_INFO = reactive({
  elements: [],
  bundles: [],
  subscriptionPackages: [],
  parentSubscriptionPackages: [],
  fullname: null,
  lastLogin: null,
});
export const NOTIFICATIONS = ref([]);
export const isBundle = ref(false);
export const SUBSCRIPTION_ELEMENTS_OPTS = computed(() => {
  const bundle = SUBSCRIPTION_INFO.bundles.find(
    (nth) => nth.code === LAST_SELECTED_ELEMENT_TO_VIEW.value
  );
  let allBundledElements = [];
  for (const bundle of SUBSCRIPTION_INFO.bundles) {
    allBundledElements = allBundledElements.concat(
      bundle.bundledElements || []
    );
  }
  let opts = SUBSCRIPTION_INFO.elements
    .filter((nth) => !allBundledElements.includes(nth.code))
    .map((nth) => {
      return {
        label: nth.name,
        value: nth.code,
        type: "ELEMENT",
      };
    });
  const bundles = SUBSCRIPTION_INFO.bundles.map((nth) => {
    return {
      label: nth.name,
      value: nth.code,
      type: "PACKAGE",
    };
  });

  if (bundle) {
    opts = SUBSCRIPTION_INFO.elements
      .filter((nth) => bundle.bundledElements.includes(nth.code))
      .map((nth) => {
        return {
          label: nth.name,
          value: nth.code,
          parent: bundle.code,
          type: "ELEMENT",
        };
      });
    isBundle.value = true;
    return sortBy(opts, "label");
  } else {
    isBundle.value = false;
    return sortBy(opts.concat(bundles), "label");
  }
});
export const SUBSCRIPTION_PACKAGES_OPTS = computed(() => {
  const bundles = (SUBSCRIPTION_INFO.subscriptionPackages || []).map((nth) => {
    return {
      label: nth.packageName,
      value: nth.id,
    };
  });
  return bundles;
});

export const PARENT_SUBSCRIPTION_PACKAGES_OPTS = computed(() => {
  const bundles = (SUBSCRIPTION_INFO?.parentSubscriptionPackages || []).map((nth) => {
    return {
      label: nth.packageName,
      value: nth.id,
    };
  });
  return bundles;
});


export const inProgress = ref(false);
export const reqSignal = ref(new AbortController());

export const getSubscriptionInfo = async () => {
  const gqlQuery = GQL_QUERIES.GET_SUBSCRIPTION_DETAILS;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;


  let client = new HttpClient(gqlQuery.baseUrl);
  await client.get(`${gqlQuery.controller}/${gqlQuery.operation}`)
    .then(response => {
      inProgress.value = false;
      const data = response;
      SUBSCRIPTION_INFO.elements = data.elements || [];
      SUBSCRIPTION_INFO.bundles = data.bundles || [];
      SUBSCRIPTION_INFO.subscriptionPackages = data.subscriptionPackages || [];
      SUBSCRIPTION_INFO.parentSubscriptionPackages = data.parentSubscriptionPackages || [];
      SUBSCRIPTION_INFO.fullname = data.fullName;
      SUBSCRIPTION_INFO.lastLogin = data.lastLogin;
    });

  //const response = await gqlRequest(payload, reqSignal.value.signal);


};
export const getNotifications = async () => {
  const gqlQuery = GQL_QUERIES.GET_NOTIFICATIONS;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;


  let client = new HttpClient(gqlQuery.baseUrl);
  client.get(`${gqlQuery.controller}/${gqlQuery.operation}`)
    .then(response => {
      NOTIFICATIONS.value = response;
      inProgress.value = false;
    });
};

